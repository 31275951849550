import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import NewsList from '../components/News-components/NewsList-component'

const AllNewsPage = ({ data }) => {
  if (!data) return null
  const doc = data.prismicCollectionPage.data
  const docs = data.allPrismicNews
  const pageContent = data.prismicCollectionPage
  const { lang, type, url } = pageContent
  const page = pageContent.data || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicMenuLink || {}

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.title.text} lang={lang} />
      <h1 className="collection-page-title">{doc.title.text} </h1>
      <NewsList docs={docs} />
    </Layout>
  )
}

export const query = graphql`
  query newsCollectionPageQuery($lang: String) {
    prismicCollectionPage(lang: { eq: $lang }, uid: { eq: "all-news" }) {
      alternate_languages {
        uid
        type
        lang
      }
      type
      url
      lang
      _previewable
      data {
        title {
          text
        }
        links {
          background_image {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 321
              height: 256
            )
          }
          link {
            url
          }
          link_title {
            text
          }
        }
      }
    }
    allPrismicNews(
      filter: { lang: { eq: $lang } }
      sort: { order: DESC, fields: data___custom_publication_date }
    ) {
      nodes {
        _previewable
        alternate_languages {
          id
          lang
        }
        id
        lang
        tags
        uid
        data {
          body {
            ... on PrismicNewsDataBodyText {
              id
              slice_label
              slice_type
              primary {
                content {
                  raw
                }
              }
            }
          }
          custom_publication_date(formatString: "DD/MM/YYYY")
          main_image {
            gatsbyImageData(width: 580, layout: CONSTRAINED, placeholder: NONE)
          }
          page_title {
            raw
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(AllNewsPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
