import * as React from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Button } from 'react-bootstrap'

// import { Pagination } from '../../utils/Pagination'
// import { includes } from 'lodash'

// Function to retrieve a small preview of the post's text
const firstParagraph = (post) => {
  const firstTextSlice = post.body.find((slice) => slice.slice_type === 'text')
  if (firstTextSlice != null) {
    const textLimit = 300
    const text = RichText.asText(firstTextSlice.primary.content.raw)
    const limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      return (
        <p className='first-paragraph'>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</p>
      )
    }
    return <p>{text}</p>
  }
  return null
}

const PostSummary = ({ post, id, children }) => {
  let postDate = post.data.custom_publication_date
  const image = getImage(post.data.main_image.gatsbyImageData)
  const defaultTitle = 'Untitled'
  return (
    <div className=" news_list-wrapper">
      <div className="news_list-image">
        <Link to={post.uid}>
          <GatsbyImage image={image} alt={post.uid} />
        </Link>
      </div>
      <div className="news_list-summary" key={id}>
        <h3>
          {/* We render a link to a particular post
           * using the linkResolver for the url and its title */}
          <Link to={post.uid}>
            {RichText.asText(post.data.page_title.raw).length !== 0
              ? RichText.asText(post.data.page_title.raw)
              : defaultTitle}
          </Link>
        </h3>
        <p className="news_list-post-meta">
          <time>{postDate}</time>
        </p>
        {/* Renders a small preview of the post's text */}
        {firstParagraph(post.data)}
        <Link className="btn btn-outline-primary" to={post.uid}>
          <span>READ MORE</span>
        </Link>
      </div>
      {children}
    </div>
  )
}

const NewsPage = ({ docs }) => {
  let tag = 'news'
  const [activeTag, setActiveTag] = useState(tag)

  // if (!docs) return null
  const posts = docs.nodes.filter((node) => node.tags.includes(activeTag))

  return (
    <div className="news-posts container">
      {posts.map((post) => (
        <PostSummary post={post} key={post.id}>
          <div className="tags-wrapper">
            <hr />
            {post.tags &&
              post.tags.map((tag, key) => {
                return (
                  <Button
                    variant="outline-dark"
                    key={key}
                    className="tags"
                    onClick={() => setActiveTag(tag)}
                    onKeyDown={() => setActiveTag(tag)}
                    aria-hidden={true}
                  >
                    {tag}
                  </Button>
                )
              })}
          </div>
        </PostSummary>
      ))}
    </div>
  )
}

export default NewsPage
